import { PasskeyRegistration } from './PasskeyInterfaces';
import { AxiosInstance } from 'axios';
import type { PublicKeyCredentialCreationOptionsJSON } from '@simplewebauthn/browser';
import { startRegistration } from '@simplewebauthn/browser';

export const registerPasskey = async (apiClient: AxiosInstance): Promise<PasskeyRegistration> => {
  let registration: PasskeyRegistration = { success: false };

  try {
    // request options from server
    const optionsResponse = await apiClient.post('/webauthn/new_passkey_options');
    const optionsJSON: PublicKeyCredentialCreationOptionsJSON = optionsResponse.data;
    // console.log(optionsJSON);

    // send options to device and receive credential back
    const credential = await startRegistration({ optionsJSON });
    // console.log(credential);

    // send credential to server
    const serverResponse = await apiClient.post('/webauthn/add_passkey', JSON.stringify({ credential }));
    // console.log(serverResponse);

    registration = { success: true, user: serverResponse.data };
  } catch (e: any) {
    // console.log(e.message);
  }

  return registration;
};
