import React, { useEffect, useState, useRef, useContext } from 'react';
import { ValidatedInput, SectionalAlert, Button, Checkbox, Modal } from '../../baseComponents';
import { validateEmailFormat } from '../../../utils/utils';
import { useApiClient } from '../../../hooks/useApiClient';
import { LegalSection } from '../../LegalSection/LegalSection';
import { configurationContext, myAccountContext, pathContext } from '../../../contexts/contexts';
import PageTitleHook from '../../../utils/PageTitleHook/PageTitleHook';
import loginGov from '../../../assets/images/login_dot_gov_logo_sign_in.svg';
import './SignIn.css';
import { PasskeySignIn, signInWithPasskey } from '../../../utils/apiWrappers/PasskeysApiWrapper';
import passkeyIcon from '../../../assets/icons/passkey-icon.svg';
import MagicOr from './MagicOr';

const SignIn = () => {
  PageTitleHook('USCIS Online Account | Welcome to US Citizenship and Immigration Services');
  const { alert, setAlert, clearAlert, clearPersistentAlert, setUser } = useContext(myAccountContext);
  const { setUrl } = useContext(pathContext);
  const { configuration } = useContext(configurationContext);

  const [submitSignin, setSubmitSignin] = useState(false);
  const [disabledSubmit, setDisabledSubmit] = useState(false);
  const [pwdFieldType, setPwdFieldType] = useState('password');
  const [emailInputVal, setEmailInputVal] = useState('');
  const [passwordInputVal, setPasswordInputVal] = useState('');
  const [emailValidationMsg, setEmailValidationMsg] = useState('');
  const [passwordValidationMsg, setPasswordValidationMsg] = useState('');
  const [loginGovAlert, setLoginGovAlert] = useState({ type: '', message: '' });
  const [hideRender, setHideRender] = useState(false);

  const { appSettings, mobileView, loginDotGovEmail, loginDotGovUuid, lg_linked, lg_account_status } = configuration;

  const [loggedInWithLoginGov, setLoggedInWithLoginGov] = useState(false);
  const [loginGovModEnabled, setLoginGovModEnabled] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showLoginGovModal, setShowLoginGovModal] = useState(false);

  const alertRef = useRef<HTMLDivElement>(null);
  const loginGovAlertRef = useRef<HTMLDivElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const showPasswordRef = useRef<HTMLInputElement>(null);

  const passkeysEnabled = useRef(false);
  const BUTTONS_SIDE_BY_SIDE_WIDTH = 'w-full md:w-5/12';
  const BUTTONS_FULL_WIDTH = 'w-full';
  const signInButtonsWidth = useRef(BUTTONS_SIDE_BY_SIDE_WIDTH);

  const apiClient = useApiClient();

  const handleModalCancel = () => {
    setShowLoginGovModal(false);
  };

  const handlePasswordCheckbox = (e: any) => {
    if (e.currentTarget.checked) setPwdFieldType('text');
    else setPwdFieldType('password');
  };

  const handleEmailInputChange = (e: any) => {
    if (disabledSubmit) setDisabledSubmit(false);
    if (!loading && emailInputVal !== '') {
      if (alert.message !== '') clearAlert();
      if (emailValidationMsg !== '') setEmailValidationMsg('');
      setEmailInputVal(e.target.value);
    } else {
      setEmailInputVal(e.target.value);
    }
  };

  const handlePasswordInputChange = (e: any) => {
    if (disabledSubmit) setDisabledSubmit(false);
    if (!loading && passwordInputVal !== '') {
      if (alert.message !== '') clearAlert();
      if (passwordValidationMsg !== '') setPasswordValidationMsg('');
      setPasswordInputVal(e.target.value);
    } else {
      setPasswordInputVal(e.target.value);
    }
  };

  const validateEmailField = () => {
    if (emailInputVal === '') {
      setEmailValidationMsg('Please enter a valid email address');
    } else {
      setEmailValidationMsg(validateEmailFormat(emailInputVal.trim(), true));
    }
  };

  const validatePasswordField = () => {
    passwordInputVal === '' ? setPasswordValidationMsg('Password cannot be blank') : setPasswordValidationMsg('');
  };

  const handleSubmit = () => {
    validateEmailField();
    validatePasswordField();
    if (lg_account_status !== '') {
      handleLoggedInWithLoginDotGov();
    } else {
      setDisabledSubmit(true);
      setSubmitSignin(true);
    }
  };

  const handleLoginGov = () => {
    apiClient
      .get('/login_dot_gov/authorize')
      .then((res) => {
        if (res.data.enabled === false) {
          console.log('login dot gov mod is not enabled');
        } else {
          window.location.href = res.data.login_dot_gov_url;
        }
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const handleSignInWithPasskeyButton = async () => {
    setDisabledSubmit(true);
    const signIn: PasskeySignIn = await signInWithPasskey(apiClient);

    if (signIn.success) {
      setUser(signIn.user);
    } else {
      setAlert({ type: 'error', message: 'Sign in with Passkey failed.' });
      setDisabledSubmit(false);
    }
  };

  const handleLearnMorePasskeysButton = () => {
    clearPersistentAlert();
    setUrl('/passkeys/faq');
  };

  const handleEnterDown = (e: any) => {
    if (e.key === 'Enter') {
      if (emailValidationMsg !== '') {
        emailRef.current!.focus();
      } else if (passwordValidationMsg !== '') {
        passwordRef.current!.focus();
      } else if (!disabledSubmit) {
        handleSubmit();
      }
    }
  };

  useEffect(() => {
    setHideRender(loginDotGovEmail !== '' && lg_linked === 1);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginDotGovEmail]);

  const handleLoggedInWithLoginDotGov = () => {
    setLoggedInWithLoginGov(true);
    if (lg_linked === 1) {
      handleLoginGov();
    } else {
      setLoginGovAlert({
        type: 'info',
        message:
          'To connect your USCIS online account to Login.gov, please sign in. This will only need to be done once. If you do not have a USCIS online account, please create an account below.',
      });
    }
  };

  useEffect(() => {
    setLoginGovModEnabled(appSettings.includes('LoginGovModEnabled'));

    if (lg_account_status !== '') {
      setLoginGovAlert({ type: 'error', message: lg_account_status });
      setHideRender(false);
    } else if (loginDotGovEmail !== '' && loginDotGovUuid !== '') {
      handleLoggedInWithLoginDotGov();
    } else {
      setLoggedInWithLoginGov(false);
    }
    if (loading) {
      setDisabledSubmit(false);
      setLoading(false);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (alert.message === undefined) return;
    if (alert.message !== '') {
      setDisabledSubmit(false);
      alertRef?.current!.focus();
    } else if (loginGovAlert.message !== '' && loginGovAlertRef.current !== null) {
      loginGovAlertRef.current!.focus();
    }
  }, [alert, loginGovAlert]);

  useEffect(() => {
    passkeysEnabled.current = appSettings.includes('PasskeysEnabled');
    signInButtonsWidth.current = passkeysEnabled.current ? BUTTONS_FULL_WIDTH : BUTTONS_SIDE_BY_SIDE_WIDTH;
  });

  useEffect(() => {
    if (submitSignin) {
      if (emailValidationMsg !== '') {
        emailRef.current!.focus();
      } else if (passwordValidationMsg !== '') {
        passwordRef.current!.focus();
      } else {
        if (alert.message !== '') clearAlert();
        apiClient
          .post('/authentication/sign_in', {
            email: emailInputVal.trim(),
            password: passwordInputVal,
          })
          .then((res) => {
            if (
              res.data.msg ===
              'Your two-step verification method was originally set to SMS. Because you do not have a valid mobile number associated with your account, your two-step verification method has been automatically switched to e-mail.'
            ) {
              setAlert({
                type: 'info',
                message: res.data.msg,
                shouldPersist: true,
              });
            } else {
              clearPersistentAlert();
            }
            setUser(res.data);
          })
          .catch((error) => {
            if (error.response.data.authentication_state === 'second_factor_soft_locked') {
              setUser(error.response.data);
            } else if (error.response.data.error === 'Token already in use') {
              setUrl('/invalid-ial-token');
            } else {
              setAlert({
                message: error.response.data.error,
                type: 'error',
              });
              alertRef.current!.focus();
            }
          });
      }
      setSubmitSignin(false);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitSignin]);

  const loginGovModalBody = (
    <div className="flex flex-col space-y-2 ">
      <p>
        You are now leaving the USCIS myAccount webpage and will be directed to Login.gov website to complete your
        sign-in process.
      </p>
      <p className="py-2">
        <b>Please note:</b>
      </p>
      <ul className="pl-4 list-disc">
        <li className="pb-2">
          Login.gov is an official U.S. government system used for secure sign-ins across multiple agencies
        </li>
        <li>
          After you sign in through Login.gov, you will automatically be redirected back to myAccount to access your
          USCIS services.
        </li>
      </ul>
    </div>
  );

  return hideRender ? (
    <></>
  ) : (
    <div className="card mx-auto" data-testid="signin-container">
      <Modal
        title="Navigating to Login.gov"
        visible={showLoginGovModal}
        onCancel={handleModalCancel}
        onOk={handleLoginGov}
        info={false}
        okButtonText="Continue"
      >
        {loginGovModalBody}
      </Modal>
      <div className="mb-6">
        <h1 className="text-2xl my-3 font-normal text-dhs_font_gray">Sign In</h1>
        {alert.message !== undefined && alert.message !== '' ? (
          <div data-testid="sectional-alert-container" ref={alertRef} tabIndex={0}>
            <SectionalAlert type={alert.type}>{alert.message}</SectionalAlert>
          </div>
        ) : (
          <></>
        )}
        {loginGovAlert.type !== '' && alert.message === '' && (
          <div
            className="mt-2"
            data-testid="login-dot-gov-sectional-alert-container"
            ref={loginGovAlertRef}
            tabIndex={0}
          >
            <SectionalAlert type={loginGovAlert.type}>{loginGovAlert.message}</SectionalAlert>
          </div>
        )}
        <div data-testid="email-input" className="my-3 pt-3">
          <ValidatedInput
            label="Email Address"
            required
            msg={emailValidationMsg}
            onChange={handleEmailInputChange}
            value={emailInputVal}
            type="text"
            inputMode="email"
            ref={emailRef}
            onKeyDown={handleEnterDown}
          />
        </div>
        <div className="pt-5">
          <ValidatedInput
            label="Password"
            required
            msg={passwordValidationMsg}
            onChange={handlePasswordInputChange}
            value={passwordInputVal}
            type={pwdFieldType}
            ref={passwordRef}
            onKeyDown={handleEnterDown}
          />
        </div>
        <div className="w-full flex flex-row justify-between items-center">
          {mobileView && appSettings.includes('WebviewDevelopmentModEnabled') ? (
            <div></div>
          ) : (
            <a
              href="/forgot-password"
              data-testid="forgot-password-btn"
              className="text-dhs_light_blue hover:text-dhs_focus_blue focus:ring-1 focus:ring-dhs_focus_blue no-underline font-normal"
              onClick={(e) => {
                e.preventDefault();
                clearPersistentAlert();
                setUrl('/forgot-password');
              }}
            >
              Forgot Password?
            </a>
          )}
          <Checkbox className="mt-1" label="Show Password" ref={showPasswordRef} onChange={handlePasswordCheckbox} />
        </div>

        <div className="w-full mt-8 mb-6 flex items-center justify-between sm:flex-row flex-col button-container flex-wrap">
          <Button
            id="sign-in-btn"
            text="Sign In"
            className={`${signInButtonsWidth.current} text-md`}
            disabled={disabledSubmit}
            onMouseDown={handleSubmit}
            onKeyDown={handleEnterDown}
          />

          {passkeysEnabled.current && (
            <>
              <Button
                id="passkey-button"
                data-testid="passkey-button"
                text="Sign in with Passkey"
                ariaLabel="Sign in with Passkey"
                image={passkeyIcon}
                imageAlt="passkey icon"
                type="primary"
                disabled={disabledSubmit}
                className="w-full text-md flex justify-center items-center"
                onMouseDown={handleSignInWithPasskeyButton}
                onEnter={handleSignInWithPasskeyButton}
              />
              <div className="flex justify-end w-full">
                <a
                  href="/passkeys/faq"
                  data-testid="learn-more-passkeys-button"
                  onClick={handleLearnMorePasskeysButton}
                  className="text-dhs_light_blue hover:text-dhs_focus_light_blue hover:underline focus:ring-1 focus:ring-dhs_focus_blue focus:underline mt-2 mb-5"
                >
                  Learn more about Passkeys
                </a>
              </div>
            </>
          )}

          {loginGovModEnabled && !loggedInWithLoginGov && (
            <>
              <MagicOr passkeysEnabled={passkeysEnabled.current} />

              <Button
                id="login-gov-btn"
                text="Sign in with"
                ariaLabel="Sign in with LOGIN.GOV"
                image={loginGov}
                imageAlt="login gov logo"
                type="secondary"
                className={`${signInButtonsWidth.current} text-md flex justify-center items-center`}
                onMouseDown={() => setShowLoginGovModal(true)}
                onEnter={() => setShowLoginGovModal(true)}
              />
              <div className="flex justify-end w-full">
                <a
                  href="/login-gov-faq"
                  data-testid="learn-more-login-gov-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    clearPersistentAlert();
                    setUrl('/login-gov-faq');
                  }}
                  className="text-dhs_light_blue hover:text-dhs_focus_light_blue hover:underline focus:ring-1 focus:ring-dhs_focus_blue focus:underline mt-2 mb-8"
                >
                  Learn more about Login.gov
                </a>
              </div>
            </>
          )}
        </div>
      </div>

      {!(mobileView && appSettings.includes('WebviewDevelopmentModEnabled')) && (
        <div className="flex flex-col">
          <h2 className="text-xl">Don't have a USCIS online account?</h2>
          <a
            href="/create-account"
            data-testid="create-account-btn"
            onClick={(e) => {
              e.preventDefault();
              clearAlert();
              setUrl('/create-account');
            }}
            className="text-dhs_light_blue hover:text-dhs_focus_blue focus:ring-1 focus:ring-dhs_focus_blue hover:underline font-bold mt-1 mr-auto"
          >
            Create an account
          </a>
          <a
            href="/resend-confirmation"
            data-testid="resend-confirmation-btn"
            onClick={(e) => {
              e.preventDefault();
              clearPersistentAlert();
              setUrl('/resend-confirmation');
            }}
            className="pt-4 text-dhs_light_blue hover:text-dhs_focus_light_blue hover:underline focus:ring-1 focus:ring-dhs_focus_blue focus:underline mt-1 mr-auto"
          >
            Didn't receive confirmation instructions?
          </a>
        </div>
      )}
      {mobileView ? null : (
        <>
          <div className="bg-gray-200 h-px max-w-full mt-6"></div>
          <LegalSection />
        </>
      )}
    </div>
  );
};

export default SignIn;
