import { useEffect, useState, useContext, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { configurationContext, myAccountContext, pathContext } from '../../contexts/contexts';

let leavingApp = false;

export const resetLeavingApp = () => {
  leavingApp = false;
};

interface RedirectionProps {
  children: any;
}

const isUnAuthenticatedRoute = (pathname: string) => {
  if (pathname === '/reset-password/auth') return false;
  if (pathname === '/reset-password/create-password') return false;
  return (
    [
      '/sign-in',
      '/create-account',
      '/legal-terms',
      '/resend-confirmation',
      '/forgot-password',
      '/login-gov-faq',
      '/passkeys/faq',
    ].includes(pathname) ||
    pathname.match(/^(\/|\/account-creation\/|\/update-email\/)confirmation\/(.+)/) ||
    pathname.match(/^\/recovery-email-confirmation\/(.+)/) ||
    pathname.includes('/reset-password') ||
    pathname.includes('/invitation-expired') ||
    pathname.includes('/invalid-ial-state') ||
    pathname.includes('/invalid-ial-token') ||
    pathname.includes('/idp/results')
  );
};

const canVisitAccountTypePage = (accountType: string | null) => {
  return !accountType || accountType === 'noaccounttype';
};

const canVisitIdPaaS = (ialState: string | undefined) => {
  return (
    ialState === 'awaiting_quiz' ||
    ialState === 'started_quiz' ||
    ialState === 'failed' ||
    ialState === 'hard_fail' ||
    ialState === 'passed'
  );
};

const canVisitIdPaaSResults = (ialState: string) => {
  console.log('IALSTATE is ' + ialState);
  return ialState === 'failed' || ialState === 'passed' || ialState === 'hard_fail' || ialState === 'verified';
};

const canVisitLoginGovLinked = (lgAccountLinked: string) => {
  return lgAccountLinked === 'success' || lgAccountLinked === 'failure';
};

const shouldBeSentToDashboard = (pathname: string, user: any) => {
  if (user.authentication_state !== 'fully_signed_in') return false;
  if (['Mobile', 'App'].includes(user.unconfirmed_two_factor_method)) return false;
  if (pathname.includes('/update-email/confirmation')) return false;
  if (pathname.includes('/account-creation/confirmation')) return false;
  if (pathname === '/account-creation/backup-code' && user.second_factor_strategy === 'backup_code') return false;

  if (pathname.includes('account-creation')) return true;
  if (pathname.includes('/reset-password')) return true;

  switch (pathname) {
    case '/':
      return true;
    case '/sign-in':
      return true;
    case '/create-account':
      return true;
    case '/legal-terms':
      return true;
    case '/resend-confirmation':
      return true;
    case '/forgot-password':
      return true;
    case '/login-gov-faq':
      return true;
    case '/auth':
      return true;
    case '/users/type':
      return !canVisitAccountTypePage(user.account_type);
    case '/confirm-representative':
      return !canVisitAccountTypePage(user.account_type);
    case '/idp/index':
      return !canVisitIdPaaS(user.ial_state);
    case '/idp/question':
      return !canVisitIdPaaS(user.ial_state);
    case '/idp/results':
      return !canVisitIdPaaSResults(user.ial_state);
    case '/login-gov-linked':
      return !canVisitLoginGovLinked(user.lg_account_linked);
  }

  return false;
};

const shouldBeSendToEditAccountPage = (pathname: string, user: any) => {
  if (user.authentication_state !== 'fully_signed_in') return false;
  if (pathname === '/delete-account/verify-your-account') return false;
  if (pathname.includes('/delete-account')) return !user.account_deletion_state;
};

const directedRouteByAuthenticationState = (user: any, pathname: string) => {
  switch (user.authentication_state) {
    case 'second_factor_soft_locked':
      return '/soft-locked';
    case 'awaiting_two_factor':
      return '/auth';
    case 'incorrect_2fa_code_received':
      return incorrect2faCodeReceivedRouteHelper(user);
    case 'awaiting_terms_of_use_acceptance':
      return '/account-creation/terms-of-use';
    case 'awaiting_password_creation':
      return '/account-creation/create-password';
    case 'awaiting_2fa_method_selection':
      return awaiting2faMethodSelectionRouteHelper(pathname);
    case 'awaiting_2fa_method_confirmation':
      return awaiting2faMethodConfirmationRouteHelper(user, pathname);
    case 'awaiting_security_questions_setup':
      return awaitingSecurityQuestionsSetupRouteHelper(pathname);
    case 'tech_support_password_reset':
      return techSupportPasswordResetRouteHelper(pathname);
    case 'fully_signed_in':
      return fullySignedInRouteHelper(user);
    default:
      return '';
  }
};

// start helpers to extract some complexity out of directedRouteByAuthenticationState above (SonarQube: "Cognitive Complexity of functions should not be too high")

const incorrect2faCodeReceivedRouteHelper = (user: any) => {
  return user.unconfirmed_two_factor_method === 'Mobile' ? '/edit-account/auth' : '/auth';
};

const awaiting2faMethodSelectionRouteHelper = (pathname: string) => {
  return pathname === '/account-creation/totp' ? '' : '/account-creation/select-2fa';
};

const awaiting2faMethodConfirmationRouteHelper = (user: any, pathname: string) => {
  if (pathname === '/account-creation/select-2fa') return '';
  if (!user.unconfirmed_two_factor_method) return '/account-creation/select-2fa';

  if (user.unconfirmed_two_factor_method === 'App') {
    return '/account-creation/totp';
  } else {
    return '/account-creation/auth';
  }
};

const awaitingSecurityQuestionsSetupRouteHelper = (pathname: string) => {
  return pathname === '/account-creation/backup-code' ? '' : '/account-creation/set-security-questions';
};

const techSupportPasswordResetRouteHelper = (pathname: string) => {
  return pathname.includes('confirmation') ? '' : '/tech-reset/create-password';
};

const fullySignedInRouteHelper = (user: any) => {
  if (user.unconfirmed_two_factor_method === 'Mobile' || user.unconfirmed_two_factor_method === 'Email') {
    return '/edit-account/auth';
  } else if (user.unconfirmed_two_factor_method === 'App') {
    return '/edit-account/totp';
  } else {
    return '';
  }
};

// end directedRouteByAuthenticationState helpers

const directedRouteByResetState = (user: any) => {
  switch (user.reset_state) {
    case 'awaiting_two_factor':
      return '/reset-password/auth';
    case 'create_password':
      return '/reset-password/create-password';
    default:
      return '';
  }
};

const directedRouteByAccountDeletionState = (user: any, pathname: string) => {
  switch (user.account_deletion_state) {
    case 'password_verified':
      return '/delete-account/verify-2fa-delete-account';
    case 'second_factor_verified':
      return pathname === '/delete-account/response' ? '' : '/delete-account/confirm-delete';
    case 'deletion_failed':
      return '/delete-account/response';
    default:
      return '';
  }
};

const shouldBeSentToSignIn = (user: any, pathname: string) => {
  return !user.authentication_state && !user.reset_state && !isUnAuthenticatedRoute(pathname);
};

const shouldBeSentToLoginDotGovLinked = (lgAccountLinked?: string) => {
  return lgAccountLinked === 'success' || lgAccountLinked === 'failure';
};

const shouldBeSendToBackupCodePage = (user: any, pathname: string) => {
  if (user.authentication_state === 'fully_signed_in') {
    return (
      user.second_factor_strategy === 'backup_code' &&
      (pathname === '/account-creation/auth' || pathname === '/account-creation/totp')
    );
  } else if (user.authentication_state === 'awaiting_security_questions_setup') {
    return (
      pathname === '/account-creation/auth' ||
      pathname === '/auth' ||
      pathname === '/account-creation/totp' ||
      pathname === '/login-gov-linked'
    );
  }
};

const shouldBeSentToAccountTypePage = (accountTypeNeeded: boolean, pathname: string, accountType: string | null) => {
  return pathname === '/dashboard' && accountTypeNeeded && canVisitAccountTypePage(accountType);
};

// We need to look into this and see if we need to implement it..
const shouldReturnToSaml = (pathname: string, user: any) => {
  return (
    pathname === '/dashboard' &&
    user.authentication_state === 'fully_signed_in' &&
    user.before_dashboard === 'return_to_saml'
  );
};
const returnToSaml = (samlRoute: string) => {
  if (!leavingApp) {
    leavingApp = true;
    let host = window.location.origin;
    if (host === 'http://localhost:3004') {
      host = 'https://localhost:3003';
    }
    window.location.href = host + samlRoute;
  }
};

const getDirectedUrl = (user: any, pathname: string, configuration: any) => {
  if (user.force_redirection) {
    leavingApp = true;
    window.location.replace(user.force_redirection);
    return ''; // don't redirect anywhere (just go to the URL in force_redirection)
  }
  if (shouldBeSentToSignIn(user, pathname)) return '/sign-in';
  if (shouldBeSentToLoginDotGovLinked(user.lg_account_linked)) return '/login-gov-linked';
  if (shouldBeSendToBackupCodePage(user, pathname)) return '/account-creation/backup-code';
  if (shouldBeSentToAccountTypePage(configuration.accountTypeNeeded, pathname, user.account_type)) return '/users/type';
  // The next link might need to be reworked.
  if (shouldReturnToSaml(pathname, user)) {
    returnToSaml('/api/saml/fully_signed_in');
    return '';
  }
  if (shouldBeSentToDashboard(pathname, user)) return '/dashboard';
  if (shouldBeSendToEditAccountPage(pathname, user)) return '/edit-account';

  let directedRoute = directedRouteByAuthenticationState(user, pathname);
  if (directedRoute) return directedRoute;
  directedRoute = directedRouteByResetState(user);
  if (directedRoute) return directedRoute;
  directedRoute = directedRouteByAccountDeletionState(user, pathname);
  return directedRoute;
};

export const Redirection = (props: RedirectionProps) => {
  const { user, clearAlert } = useContext(myAccountContext);
  const { url, setUrl, headerRef } = useContext(pathContext);
  const { configuration } = useContext(configurationContext);

  const { children } = props;

  const location = useLocation();
  const navigate = useNavigate();

  const [redirecting, setRedirecting] = useState(true);

  const scrollThenNavigate = useCallback(
    (url: string) => {
      if (!!headerRef) {
        headerRef.current?.scrollIntoView();
      }
      navigate(url, { replace: true });
    },
    [headerRef, navigate]
  );

  useEffect(() => {
    if (url === location.pathname) return;

    if (url) {
      if (url.includes('http')) {
        window.location.href = url;
      } else {
        clearAlert();
        scrollThenNavigate(url);
        setRedirecting(true);
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  useEffect(
    () => {
      if (leavingApp) return;

      setRedirecting(false);

      const directedUrl = getDirectedUrl(user, location.pathname, configuration);
      if (directedUrl && directedUrl !== location.pathname) {
        clearAlert();
        scrollThenNavigate(directedUrl);
        setRedirecting(true);
        setUrl(directedUrl);
      }
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [location.pathname, user]
  );

  return redirecting || leavingApp ? null : <>{children}</>;
};
