import PageTitleHook from '../../../utils/PageTitleHook/PageTitleHook';
import React, { useContext, useRef, useState, useEffect } from 'react';
import { Button, SectionalAlert } from '../../baseComponents';
import { pathContext, myAccountContext, configurationContext } from '../../../contexts/contexts';
import './passkeys-register.css';
import fingerPrintIcon from '../../../assets/icons/passkey-fingerprint-icon.svg';
import { useApiClient } from '../../../hooks/useApiClient';
import { PasskeyRegistration, registerPasskey } from '../../../utils/apiWrappers/PasskeysApiWrapper';

export const PasskeysRegister = () => {
  const { configuration } = useContext(configurationContext);
  const { appSettings } = configuration;
  const { setUrl } = useContext(pathContext);
  const { alert, setAlert, setUser } = useContext(myAccountContext);
  const [registrationCompleted, setRegistrationCompleted] = useState<boolean>(false);
  PageTitleHook('USCIS Online Account | Register Passkey');

  useEffect(() => {
    if (!appSettings.includes('PasskeysEnabled')) {
      setUrl('/edit-account');
    }
  }, []);

  const sectionalAlertRef = useRef<HTMLDivElement>(null);
  const apiClient = useApiClient();

  const handleCancelReturnButton = (e: any) => {
    setRegistrationCompleted(false);
    setUrl('/edit-account');
  };

  const handleCreatePasskeyButton = async (e: any) => {
    const registration: PasskeyRegistration = await registerPasskey(apiClient);

    if (registration.success) {
      setUser(registration.user);
      setUrl('/passkeys');
      setAlert({ type: 'success', message: 'Your passkey method has been successfully added.', shouldPersist: true });
    } else {
      setAlert({ type: 'error', message: 'There was a problem adding your passkey.' });
    }

    setRegistrationCompleted(true);
  };

  return (
    <div className="card" data-testid="register-passkey-container">
      <h1 className="card-header text-left" data-testid="page-title">
        Register Passkey
      </h1>

      {registrationCompleted && (
        <div id="sectional-alert-2fa" ref={sectionalAlertRef} className="mb-6">
          <SectionalAlert type={alert.type}>{alert.message}</SectionalAlert>
        </div>
      )}

      <div className="card-body p-0 text-center mt-4 mb-8">
        <div className="m-10 flex justify-center items-center">
          <img src={fingerPrintIcon} alt="fingerprint icon" className="fingerprint-icon" />
        </div>
        <p className="my-3 sign-in-faster">Sign in faster next time</p>
        <p className="sign-in-securely">
          You can sign in securely with your passkey using <br /> your fingerprint, face, or other screen-lock method.
        </p>
        <p className="my-10">
          <a
            target="_blank"
            rel="noreferrer"
            href="/something-about-passkeys"
            aria-label="Learn more about Passkeys"
            className="nav-link"
          >
            Learn more about Passkeys
          </a>
        </p>

        {registrationCompleted ? (
          <div className="my-5 text-center">
            <Button
              id="create-passkey-return-btn"
              data-testid="create-passkey-return-btn"
              text="Return to Edit Account"
              onClick={handleCancelReturnButton}
              type="return"
            />
          </div>
        ) : (
          <>
            <div className="my-10 text-center">
              <Button
                id="create-passkey-btn"
                data-testid="create-passkey-btn"
                text="Create Passkey"
                onClick={handleCreatePasskeyButton}
                type="primary"
              />
            </div>
            <div className="my-10 text-center">
              <Button
                id="create-passkey-cancel-btn"
                data-testid="create-passkey-cancel-btn"
                text="Cancel"
                onClick={handleCancelReturnButton}
                type="cancel"
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PasskeysRegister;
