import React, { useState } from 'react'
import { SectionalAlertInfoIcon } from '../../../assets/icons'
import { XClose } from '../../../assets/icons/xClose'
import './MoreInfoContainer.css'

type MoreInfoContainerProps = {
  className?: string,
  maxHeight: string,
  dataTestid: string,
  title: string,
  containerOpened: boolean
  setContainerOpened: (bool: boolean) => void,
  children: any
}

export const MoreInfoContainer = (props: MoreInfoContainerProps) => {
  const { className, maxHeight, dataTestid, title, containerOpened, setContainerOpened, children } = props

  const handleClose = () => {
    setContainerOpened(false)
  }

  const onKeyPress = (keyEvent: any) => {
    if (keyEvent.key === 'Enter') {
      setContainerOpened(false)
    }
  }

  return <div
    className={`more-info-container ${containerOpened ? 'opened' : 'closed'} ${className}`}
    style={{ maxHeight: containerOpened ? maxHeight : '0px' }}
    data-testid={dataTestid}
  >
    <div className="more-info-content flex">
      <div className="more-info-content-icon">
        <SectionalAlertInfoIcon />
      </div>
      <div className="flex-grow">
        <div className="flex flex-col">
          <div className="mt-4 mb-2">
            <b>{title}</b>
          </div>

          {children}
        </div>
      </div>
      <button
        className="more-info-close-button cursor-pointer"
        data-testid="more-info-close-btn"
        aria-label={`Close ${title} dialogue`}
        tabIndex={containerOpened ? 0 : -1}
        onClick={handleClose}
        onKeyPress={onKeyPress}
      >
        <XClose />
      </button>
    </div>
  </div>
}

type MoreInfoTriggerProps = {
  className?: string,
  dataTestid: string,
  ariaLabel: string,
  containerOpened: boolean
  setContainerOpened: (bool: boolean) => void,
}

export const MoreInfoTrigger = (props: MoreInfoTriggerProps) => {
  const { className, dataTestid, ariaLabel, containerOpened, setContainerOpened } = props

  const [hoverTimerId, setHoverTimerId] = useState(0)


  const handleHoverStart = () => {
    const timerId = window.setTimeout(() => {
      setContainerOpened(true)
    }, 500)
    setHoverTimerId(timerId)
  }

  const handleHoverEnd = () => {
    window.clearTimeout(hoverTimerId)
  }

  const onKeyPress = (keyEvent: any) => {
    if (keyEvent.key === 'Enter') {
      setContainerOpened(true)
    }
  }

  const onClick = () => {
    window.clearInterval(hoverTimerId)
    setContainerOpened(true)
  }

  return <button
    className={`more-info-toggle-icon ${className}`}
    data-testid={dataTestid}
    aria-label={ariaLabel}
    onMouseEnter={handleHoverStart}
    onMouseLeave={handleHoverEnd}
    onClick={onClick}
    onKeyPress={onKeyPress}
    tabIndex={containerOpened ? -1 : 0}
  >
    <SectionalAlertInfoIcon />
  </button>
}
