import { PasskeyListItem, PasskeyRemoval } from './PasskeyInterfaces';
import { AxiosInstance } from 'axios';

export const removePasskey = async (apiClient: AxiosInstance, passkey: PasskeyListItem): Promise<PasskeyRemoval> => {
  let removal: PasskeyRemoval = { success: false };

  try {
    const removePasskeyResponse = await apiClient.post('/webauthn/remove_passkey', { id: passkey.id });

    removal = { success: true, user: removePasskeyResponse.data };
  } catch (e: any) {
    // console.log(e.message);
  }

  return removal;
};
