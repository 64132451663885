import { PasskeySignIn } from './PasskeyInterfaces';
import { AxiosInstance } from 'axios';
import type { PublicKeyCredentialRequestOptionsJSON } from '@simplewebauthn/browser';
import { startAuthentication } from '@simplewebauthn/browser';

export const signInWithPasskey = async (apiClient: AxiosInstance): Promise<PasskeySignIn> => {
  let signIn: PasskeySignIn = { success: false };

  try {
    // request challenge options from server
    const challengeResponse = await apiClient.get('/webauthn/generate_challenge');
    const optionsJSON: PublicKeyCredentialRequestOptionsJSON = challengeResponse.data;
    // console.log(optionsJSON);

    // send challenge options to device and receive credential back
    const credential = await startAuthentication({ optionsJSON });
    // console.log(credential);

    // send credential to server
    const serverResponse = await apiClient.post('/authentication/sign_in_with_passkey', { credential });
    // console.log(serverResponse);

    signIn = { success: true, user: serverResponse.data };
  } catch (e: any) {
    // console.log(e.message);
  }

  return signIn;
};
