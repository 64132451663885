interface MagicOrProps {
  passkeysEnabled: boolean;
}

const MagicOr = (props: MagicOrProps) => {
  const { passkeysEnabled } = props;

  return passkeysEnabled ? (
    <div className="bg-gray-200 h-px w-full my-5 flex items-center justify-center">
      <div className="p-3 bg-white w-auto h-auto">OR</div>
    </div>
  ) : (
    <div className="flex items-center justify-center">OR</div>
  );
};

export default MagicOr;
