import React, { useContext } from 'react';
import { useSignOut } from '../../../hooks/hooks';
import { useApiClient } from '../../../hooks/useApiClient';
import LogoutIcon from '../../../assets/icons/LogoutIcon';
import { Button } from '../../baseComponents';
import { configurationContext } from '../../../contexts/contexts';
export const SignOut = () => {
  const signOut = useSignOut();
  const apiClient = useApiClient();

  const { configuration } = useContext(configurationContext);

  const handleSignOut = async () => {
    if (configuration.loginDotGovEmail !== '') {
      handleLoginGov();
    } else {
      signOut();
    }
  };

  const handleLoginGov = () => {
    apiClient
      .get('/login_dot_gov/sign_out')
      .then((res) => {
        if (res.data.enabled === false) {
          console.log('login dot gov mod is not enabled');
          return;
        } else {
          window.location.href = res.data.login_dot_gov_url;
        }
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  return (
    <div className="flex justify-end items-center">
      <Button id="sign_out" className="" data-method="delete" type="signout-button" onClick={handleSignOut}>
        <div className="flex flex-row justify-center text-md whitespace-nowrap mr-3 hover:underline no-underline text-center">
          <LogoutIcon className="w-4 h-4 mt-1 mr-1" />
          Sign Out
        </div>
      </Button>
    </div>
  );
};
