import React, {
  useRef,
  useEffect,
  ChangeEventHandler,
  useContext,
  useState,
  MouseEventHandler,
  ChangeEvent,
} from 'react'
import { useApiClient } from '../../../hooks/useApiClient'
import { myAccountContext, pathContext, configurationContext } from '../../../contexts/contexts'
import { ValidatedInput, Button, SectionalAlert, Checkbox } from '../../baseComponents'
import { defaultUser } from '../../../reducer/myAccountInitialState'
import { MoreInfoContainer, MoreInfoTrigger } from '../../baseComponents/MoreInfoContainer/MoreInfoContainer'
interface TwoFactorAuthenticationProps {
  label: string
  value: string
  handleChange: ChangeEventHandler<HTMLInputElement>
  handleCancel: MouseEventHandler<HTMLButtonElement>
  validate: Function
  validateMsg: string
  text: any
  children: any
  setUseBackupCode: Function
  useBackupCode: boolean
  type: 'account-creation' | 'sign-in' | 'edit' | 'change-mobile' | 'reset-password' | 'delete-account'
}

export const TwoFactorAuthentication = (props: TwoFactorAuthenticationProps) => {
  const {
    label,
    value,
    handleChange,
    handleCancel,
    validate,
    validateMsg,
    text,
    children,
    setUseBackupCode,
    useBackupCode,
    type,
  } = props

  const { alert, setAlert, clearPersistentAlert, setUser, token } = useContext(myAccountContext)
  const { setUrl } = useContext(pathContext)

  const inputRef = useRef<HTMLDivElement>(null)
  const backButtonRef = useRef<HTMLButtonElement>(null)
  const cancelButtonRef = useRef<HTMLButtonElement>(null)

  const sectionalAlertRef = useRef<HTMLDivElement>(null)
  const [disabledSubmit, setDisabledSubmit] = useState(false)
  const apiClient = useApiClient()
  const keyDownHandler = (e: any) => {
    if (e.key === 'Enter') {
      if (validateMsg !== '') {
        inputRef.current!.focus()
      } else {
        handleSubmit()
      }
    }
  }

  const { configuration } = useContext(configurationContext)
  const { appSettings } = configuration
  const rememberMeAllowed =
    appSettings.includes('SecondFactorRememberMeEnabled') && type === 'sign-in' && !useBackupCode
  const [rememberMe, setRememberMe] = useState(false)

  useEffect(() => {
    if (validateMsg !== '') inputRef.current!.focus()
  }, [validateMsg])

  useEffect(() => {
    document.title = useBackupCode
      ? 'USCIS Online Account | Backup code'
      : 'USCIS Online Account | Enter the secure verification code'
  }, [useBackupCode])

  const [containerOpened, setContainerOpened] = useState(false)

  const handleSubmit = () => {
    clearPersistentAlert()
    validate()

    if (validateMsg.length > 0 || value.length === 0) {
      return
    }

    setDisabledSubmit(true)

    switch (type) {
      case 'account-creation':
        handleCreateAccountSubmit()
        break
      case 'sign-in':
        handleSignInSubmit()
        break
      case 'edit':
        handleChange2FASubmit()
        break
      case 'change-mobile':
        handleChangeMobileSubmit()
        break
      case 'reset-password':
        handleResetPasswordSubmit()
        break
      case 'delete-account':
        handleDeleteAccountSubmit()
        break
    }
  }

  const handleCreateAccountSubmit = () => {
    apiClient
      .post('/users/confirm_second_factor_method', { verification_code: value })
      .then((res) => {
        setUrl('/account-creation/backup-code')
        setUser(res.data)
      })
      .catch((err) => {
        setAlert({
          type: 'error',
          message: err.response.data.error,
        })
        setUser(err.response.data)
      })
      .finally(() => {
        setDisabledSubmit(false)
      })
  }

  const setLinkedLoginGovAlert = (data: { lg_account_linked: string; msg: string }) => {
    setAlert({
      type: data.lg_account_linked === 'success' ? 'success' : 'error',
      message: data.msg,
      shouldPersist: true,
    })
  }

  const handleSignInSubmit = () => {
    if (useBackupCode) {
      apiClient
        .post('/authentication/backup_code', { backup_code: value })
        .then((res) => {
          if (alert.message !== '') clearPersistentAlert()
          setUser(res.data)
          if (res.data.lg_account_linked) setLinkedLoginGovAlert(res.data)
        })
        .catch((err) => {
          setUser(err.response.data)
          if (err.response.data.authentication_state === 'incorrect_2fa_code_received') {
            setAlert({
              type: 'error',
              message: 'Backup code is invalid.',
            })
          }
        })
        .finally(() => {
          setDisabledSubmit(false)
        })
    } else {
      apiClient
        .post('/authentication/verification_code', { verification_code: value, remember_me: rememberMe })
        .then((res) => {
          if (alert.message !== '') clearPersistentAlert()
          setUser(res.data)
          if (res.data.lg_account_linked) setLinkedLoginGovAlert(res.data)
        })
        .catch((err) => {
          // This isn't the cleanest way to do this. Ideally we return the session even on a failure then we can just set the user.
          if (err.response.data.authentication_state) {
            setUser(err.response.data)
          }
          if (err.response.data.authentication_state === 'incorrect_2fa_code_received') {
            setAlert({
              type: 'error',
              message: 'Please enter a valid verification code.',
            })
          }
        })
        .finally(() => {
          setDisabledSubmit(false)
        })
    }
  }

  const handleResetPasswordSubmit = () => {
    setDisabledSubmit(true)
    apiClient
      .post('/password_reset/verify_second_factor', { code: value, token })
      .then((res) => {
        setUrl('/edit-account')
        setUser(res.data)
      })
      .catch((err) => {
        setAlert({
          type: 'error',
          message: err.response.data.error,
        })
        // This isn't the cleanest way to do this. Ideally we return the session even on a failure then we can just set the user.
        if (err.response.data.authentication_state) {
          setUser(err.response.data)
        }
      })
      .finally(() => {
        setDisabledSubmit(false)
      })
  }

  const handleChange2FASubmit = () => {
    apiClient
      .post('/users/confirm_second_factor_method', { verification_code: value })
      .then((res) => {
        setUrl('/edit-account')
        setUser(res.data)
      })
      .catch((err) => {
        setAlert({
          type: 'error',
          message: err.response.data.error,
        })
        // This isn't the cleanest way to do this. Ideally we return the session even on a failure then we can just set the user.
        if (err.response.data.authentication_state) {
          setUser(err.response.data)
        }
      })
      .finally(() => {
        setDisabledSubmit(false)
      })
  }

  const handleChangeMobileSubmit = () => {
    apiClient
      .post('/users/confirm_mobile_number', { code: value })
      .then((res) => {
        setUrl('/edit-account')
        setUser(res.data)
      })
      .catch((err) => {
        setAlert({
          type: 'error',
          message: err.response.data.error,
        })
        // This isn't the cleanest way to do this. Ideally we return the session even on a failure then we can just set the user.
        if (err.response.data.authentication_state) {
          setUser(err.response.data)
        }
      })
      .finally(() => {
        setDisabledSubmit(false)
      })
  }

  const handleDeleteAccountSubmit = () => {
    apiClient
      .post('/account_deletion/verify_second_factor', { code: value, token })
      .then((res) => {
        setUser(res.data)
      })
      .catch((err) => {
        setAlert({
          type: 'error',
          message: err.response.data.error,
          shouldPersist: true,
        })
        if (err.response.data.error.includes('You have been signed out')) {
          setUser(defaultUser)
        } else {
          // This isn't the cleanest way to do this. Ideally we return the session even on a failure then we can just set the user.
          if (err.response.data.authentication_state) {
            setUser(err.response.data)
          }
        }
      })
      .finally(() => {
        setDisabledSubmit(false)
      })
  }

  const handleRememberMeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRememberMe(event.target.checked)
  }

  return (
    <div data-testid="twoFA-container">
      <h1 className="text-2xl my-3 font-normal text-dhs_font_gray">{label}</h1>

      {alert.message !== '' && (
        <div tabIndex={0} id="sectional-alert-2fa" ref={sectionalAlertRef} className="mb-6">
          <SectionalAlert type={alert.type}>{alert.message}</SectionalAlert>
        </div>
      )}
      <div className="text-default" data-testid="two-factor-message">
        {text}
      </div>
      <div data-testid="twoFA-input" className="my-3 pt-3">
        <ValidatedInput
          label={`${label.includes('Verification') ? 'Secure ' : ''}${label}`}
          required
          msg={validateMsg}
          onChange={handleChange}
          value={value}
          type={'text'}
          inputMode={useBackupCode ? 'text' : 'numeric'}
          ref={inputRef}
          onKeyDown={keyDownHandler}
        />
      </div>

      {rememberMeAllowed && (
        <>
          <div className="my-3 text-right flex justify-end">
            <Checkbox id="remember-me-checkbox" label="Remember this browser" onChange={handleRememberMeChange} />
            <MoreInfoTrigger
              className='inline-block'
              dataTestid='password-tips-toggle'
              ariaLabel='Remember this browser more info'
              containerOpened={containerOpened}
              setContainerOpened={setContainerOpened}
            />
          </div>
          <MoreInfoContainer
            className='mb-4'
            maxHeight='185px'
            dataTestid='password-tips-container'
            title='Remember this browser'
            containerOpened={containerOpened}
            setContainerOpened={setContainerOpened}
          >
            <p className='mb-3'>
              If checked you will not be prompted to enter in your one-time verification code for Multi-Factor Authentication for 24 hours within this same browser.
            </p>

          </MoreInfoContainer>
        </>
      )}

      <div className={`button-container sm:mt-8 ${useBackupCode ? 'mb-8' : ''}`}>
        <Button
          id="2fa-submit-btn"
          text="Submit"
          disabled={disabledSubmit}
          onMouseDown={handleSubmit}
          onKeyDown={keyDownHandler}
        />
        {useBackupCode && (
          <Button
            id="2fa-back-btn"
            text="Back"
            type="secondary"
            ref={backButtonRef}
            onClick={() => setUseBackupCode(false)}
          />
        )}
        {type === 'delete-account' && (
          <Button
            id="2fa-cancel-btn"
            data-testid="test-cancel-btn"
            text="Cancel"
            type="cancel"
            ref={cancelButtonRef}
            onClick={handleCancel}
          />
        )}
      </div>
      {children}
    </div>
  )
}
